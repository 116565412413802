// src/context/CartContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';

const CartContext = createContext();

const CartProvider = ({ children }) => {
    const { auth } = useContext(AuthContext);
    const [cart, setCart] = useState([]);

    useEffect(() => {
        if (auth) {
            // Load user's cart from local storage or backend
            const storedCart = localStorage.getItem(`cart_${auth.sub.email}`);
            if (storedCart) {
                setCart(JSON.parse(storedCart));
            }
        } else {
            setCart([]);
        }
    }, [auth]);

    useEffect(() => {
        if (auth) {
            // Save user's cart to local storage or backend
            localStorage.setItem(`cart_${auth.sub.email}`, JSON.stringify(cart));
        }
    }, [cart, auth]);
    
    const addToCart = (product) => {
        setCart((prevCart) => {
            const existingProduct = prevCart.find((item) => item.product_id === product.product_id);
            if (existingProduct) {
                return prevCart.map((item) =>
                    item.product_id === product.product_id
                        ? { ...item, quantity: item.quantity + 1 }
                        : item
                );
            } else {
                return [...prevCart, { ...product, quantity: 1 }];
            }
        });
    };

    const removeFromCart = (product_id) => {
        setCart((prevCart) => prevCart.filter((item) => item.product_id !== product_id));
    };

    const increaseQuantity = (product_id) => {
        setCart((prevCart) =>
            prevCart.map((item) =>
                item.product_id === product_id ? { ...item, quantity: item.quantity + 1 } : item
            )
        );
    };

    const decreaseQuantity = (product_id) => {
        setCart((prevCart) =>
            prevCart.map((item) =>
                item.product_id === product_id && item.quantity > 1
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            )
        );
    };

    const clearCart = () => {
        setCart([]);
    };

    const isInCart = (product_id) => {
        return cart.some((item) => item.product_id === product_id);
    };

    const cartCount = cart.reduce((count, item) => count + item.quantity, 0);

    return (
        <CartContext.Provider
            value={{
                cart,
                addToCart,
                removeFromCart,
                increaseQuantity,
                decreaseQuantity,
                clearCart,
                isInCart,
                cartCount
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };
