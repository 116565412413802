import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import logo from '../styles/images/logo.png'; // Ensure the logo.png file exists in the specified path

const Footer = () => {
    return (
        <footer className="bg-dark text-light py-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <h5 className="text-uppercase">About</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/about-us" className="text-light">About Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-4 mb-3">
                        <h5 className="text-uppercase">Support</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/contact-us" className="text-light">Contact Us</Link></li>
                            <li><Link to="/chat" className="text-light">Chat</Link></li>
                            <li><Link to="/review-website" className="text-light">Review Website</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-4 text-center">
                        <img src={logo} alt="Logo" className="footer-logo mb-3" />
                    </div>
                </div>
                <div className="text-center mt-3">
                    <p>&copy; 2024 LipsFillerLab, USA</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
