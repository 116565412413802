import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { CartContext } from '../context/CartContext';
import { FaUserCircle, FaShoppingCart } from 'react-icons/fa';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Header.css'

const Header = () => {
    const { auth, logout } = useContext(AuthContext);
    const { cartCount } = useContext(CartContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const navigate = useNavigate();
    const profileMenuRef = useRef(null);
    const userIconRef = useRef(null);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (searchQuery.trim()) {
                try {
                    const response = await axios.get(`/api/recommendations?query=${searchQuery}`);
                    setSuggestions(response.data);
                } catch (error) {
                    console.error("Error fetching recommendations", error);
                }
            } else {
                setSuggestions([]);
            }
        };

        fetchSuggestions();
    }, [searchQuery]);

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            setSuggestions([]);
            navigate(`/products?search=${searchQuery}`);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchQuery('');
        setSuggestions([]);
        navigate(`/product/${suggestion.product_id}`);
    };

    return (
        <header className="bg-light shadow-sm py-2">
            <nav className="navbar navbar-expand-lg navbar-light container">
                <Link to="/" className="navbar-brand fw-bold">LIPS FILLER</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <div className="container-fluid">
                        <div className="row w-100">
                            {/* Account Info - Shown above search bar on small screens */}
                            <div className="col-12 d-flex align-items-center justify-content-end d-lg-none mb-2">
                                {auth ? (
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-secondary d-flex align-items-center"
                                            ref={userIconRef}
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <FaUserCircle size={24} className="text-primary" />
                                            <span className="ms-2 d-none d-md-inline">{auth.sub.name}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end" ref={profileMenuRef}>
                                            <li><Link to="/orders" className="dropdown-item">Orders</Link></li>
                                            <li><button onClick={logout} className="dropdown-item">Logout</button></li>
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="d-flex">
                                        <Link to="/login" className="btn btn-outline-primary me-2">Login</Link>
                                        <Link to="/register" className="btn btn-primary">Register</Link>
                                    </div>
                                )}
                                <div className="ms-3 position-relative">
                                    <Link to="/cart" className="btn btn-outline-secondary position-relative">
                                        <FaShoppingCart size={24} />
                                        {cartCount > 0 && (
                                            <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                                                {cartCount}
                                            </span>
                                        )}
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-items-center order-lg-1">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/products" className="nav-link">Products</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-4 position-relative order-lg-2">
                                <form onSubmit={handleSearch} className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        placeholder="Search for products..."
                                    />
                                    <button type="submit" className="btn btn-outline-success">Search</button>
                                </form>
                                {suggestions.length > 0 && (
                                    <ul className="list-group position-absolute w-100" style={{ zIndex: 1000 }}>
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item list-group-item-action"
                                                onClick={() => handleSuggestionClick(suggestion)}
                                            >
                                                {suggestion.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className="col-md-4 d-flex align-items-center justify-content-end order-lg-3 d-none d-lg-flex">
                                {auth ? (
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-outline-secondary d-flex align-items-center"
                                            ref={userIconRef}
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <FaUserCircle size={24} className="text-primary" />
                                            <span className="ms-2 d-none d-md-inline">{auth.sub.name}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end" ref={profileMenuRef}>
                                            <li><Link to="/orders" className="dropdown-item">Orders</Link></li>
                                            <li><button onClick={logout} className="dropdown-item">Logout</button></li>
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="d-flex">
                                        <Link to="/login" className="btn btn-outline-primary me-2">Login</Link>
                                        <Link to="/register" className="btn btn-primary">Register</Link>
                                    </div>
                                )}
                                <div className="ms-3 position-relative">
                                    <Link to="/cart" className="btn btn-outline-secondary position-relative">
                                        <FaShoppingCart size={24} />
                                        {cartCount > 0 && (
                                            <span className="badge bg-danger position-absolute top-0 start-100 translate-middle">
                                                {cartCount}
                                            </span>
                                        )}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
