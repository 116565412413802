import React, { useEffect, useContext, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { CartProvider } from './context/CartContext';
import PrivateRoute from './components/PrivateRoute';
import './App.css';

const Home = lazy(() => import('./pages/Home'));
const ProductList = lazy(() => import('./pages/ProductList'));
const SingleProduct = lazy(() => import('./pages/SingleProduct'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Cart = lazy(() => import('./pages/Cart'));
const Checkout = lazy(() => import('./pages/Checkout'));
const AddressForm = lazy(() => import('./pages/AddressForm'));
const PaymentPage = lazy(() => import('./pages/PaymentPage'));
const OrderConfirmation = lazy(() => import('./pages/OrderConfirmation'));
const Orders = lazy(() => import('./pages/Orders'));
const AdminDashboard = lazy(() => import('./pages/AdminDashboard'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Chat = lazy(() => import('./pages/Chat'));
const ReviewWebsite = lazy(() => import('./pages/ReviewWebsite'));

const App = () => {
    return (
        <AuthProvider>
            <CartProvider>
                <Router>
                    <AppRoutes />
                </Router>
            </CartProvider>
        </AuthProvider>
    );
};

const AppRoutes = () => {
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (auth) {
                const message = 'You will be logged out. Are you sure you want to leave?';
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [auth]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route
                    path="/admin"
                    element={
                        <PrivateRoute>
                            <AdminDashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <>
                            <Header />
                            <main>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/products" element={<ProductList />} />
                                        <Route path="/product/:productId" element={<SingleProduct />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/register" element={<Register />} />
                                        <Route path="/about-us" element={<AboutUs />} />
                                        <Route path="/contact-us" element={<ContactUs />} />
                                        <Route path="/chat" element={<Chat />} />
                                        <Route path="/review-website" element={<ReviewWebsite />} />
                                        <Route path="/cart" element={<PrivateRoute><Cart /></PrivateRoute>} />
                                        <Route path="/checkout" element={<PrivateRoute><Checkout /></PrivateRoute>} />
                                        <Route path="/address" element={<PrivateRoute><AddressForm /></PrivateRoute>} />
                                        <Route path="/final-payment" element={<PrivateRoute><PaymentPage /></PrivateRoute>} />
                                        <Route path="/order-confirmation" element={<PrivateRoute><OrderConfirmation /></PrivateRoute>} />
                                        <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
                                        <Route path="/forgot-password" element={<ForgotPassword />} />
                                    </Routes>
                                </Suspense>
                            </main>
                            <Footer />
                        </>
                    }
                />
            </Routes>
        </Suspense>
    );
};

export default App;
